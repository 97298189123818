<template>
  <div class="refrence_warp">
    <div class="top">
      <div class="left">
        <div class="head">
          <div class="avatar">
            <img src="@/assets/bg-头像@2x.png"
                 alt="">
          </div>
          <div class="text">
            <span><span>{{info.nickname}}</span></span>
            <span style="padding :0 20rem">|</span>
            <span>{{info.gradeName}}</span>
          </div>
        </div>
        <div class="shuju">
          学习数据统计
        </div>
        <div class="number">
          <div class="item">
            <div class="m">
              {{info.totalPointsWon}}
            </div>
            <div class="n">
              总得分率(%)
            </div>
          </div>

          <div class="item">
            <div class="m">
              {{info.totalPaper}}
            </div>
            <div class="n">
              总提交试卷
            </div>
          </div>
          <div class="item">
            <div class="m">
              {{info.questions}}
            </div>
            <div class="n">
              完成题目
            </div>
          </div>

        </div>
      </div>
      <!-- 图表，知识点曲线 -->
      <div class="right">
        <quxian ref="quxianRefs" />
      </div>
    </div>

    <div class="bottom">
      <div class="left_leida">
        <div class="title">
          学情分析
        </div>
        <div class="desc"> <i v-show="upNumber"
             class="el-icon-top"></i>
          {{upNumber}}</div>
        <!-- 雷达图 -->
        <div id="leida"
             style="width: 600rem;height: 400rem;">
        </div>
        <div class="liang">
          <div class="up"
               @click="checkThisWeek">
            <span :style="{'background':week}"></span>
            本周做题量
          </div>
          <div class="down"
               @click="checkLastWeek">
            <span :style="{'background':last}"></span>
            上周做题量
          </div>
        </div>
        <!-- <div class="yuan"></div> -->
      </div>
      <div class="right_defenlv">
        <div class="title">
          得分率(%)
          <div class="more"
               @click="toLeida">
            更多<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="point_warp">
          <div class="point_item"
               v-for="(item,index) in info.score"
               :key="index">
            <div class="name">
              {{item.typeName}}
            </div>
            <div class="progress">
              <div class="my_progress">
                <div :style="{'background':getProgressBakcground(item),'width': getMath(item) + '%','height':'18rem','border-radius':'10rem'}"></div>
              </div>
              <div class="txt"
                   style="">
                {{getMath(item)}}
              </div>
            </div>
            <div class="status">
              <i class="el-icon-top"
                 style="color:#FF6147;font-size:30rem"
                 v-if="item.contrast > 0"></i>
              <i class="el-icon-bottom"
                 style="color:#FF6147;font-size:30rem"
                 v-else-if="item.contrast < 0"></i>
              <i class="el-icon-minus"
                 style="color:#37A0F4;font-size:40rem"
                 v-else></i>
              <span>
                {{Math.abs(item.contrast)!=0?Math.abs(item.contrast):''}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="autoModal"
         v-if="autoModalFlag">
      <div class="openButton">

        <el-button type="warning"
                   @click="toBuy"
                   class="btntext"><span class="text">开通VIP享受全部功能</span></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import quxian from './components/quxian.vue'
import { getStudentDetail } from '@/api/analysis.js'
import  {nowDateTime} from '@/utils/date.js'

export default {
  data () {
    return {
      indicator: [
        //修改文字颜色 #37A0F4
      ],
      xValues: [],
      yValues: [],
      info: {},
      this_week: [],
      last_week: [],
      upNumber: '',
      last: '#E5E5E5',
      week: '#37A0F4',
      myChart: undefined,
      autoModalFlag: false
    }
  },
  components: {
    quxian
  },
  mounted () {
    this.getInfo()
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
      this.autoModalFlag = true
    }
    this.getVipTime()
  },
  methods: {
    getVipTime () {
      const currentTime = nowDateTime();
      this.$http({
        url: '/api/v1/user/buy_order_time',
        method: 'get'
      }).then(res => {
        if (res.data.report_end_time > currentTime) {
          this.autoModalFlag = false
        }
      })
    },
    toBuy () {
      this.$router.push('/buyVip')
    },
    async getInfo () {
      // this.$http({
      //   url: '/api/v1/report/get_student_detail',
      //   method: 'get'
      // }).then(res => {
      //   this.indicator = []
      //   this.info = res.data

      //   this.info.this_week_question_num.forEach(item => {
      //     let subject = {
      //       name: item.subject_name,
      //       color: ''
      //     }
      //     this.this_week.push(item.num)
      //     this.indicator.push(subject)
      //   })

      //   this.info.last_week_question_num.forEach(item => {
      //     this.last_week.push(item.num)
      //   })
      //   this.drawLine()
      //   this.removeSymbol()
      // })
      const { data } = await getStudentDetail()
      this.indicator = []
      this.info = data
      this.info.thisWeekQuestionNum.forEach(item => {
        let subject = {
          name: item.subjectName,
          color: ''
        }
        this.this_week.push(item.num)
        this.indicator.push({ 'text': item.subjectName })
      })

      this.info.lastWeekQuestionNum.forEach(item => {
        this.last_week.push(item.num)
      })
      this.drawLine()
      // this.removeSymbol()


    },
    removeSymbol () {
      setTimeout(() => {
        let progress = document.getElementsByClassName('el-progress__text')
        for (let index = 0; index < progress.length; index++) {
          progress[index].innerHTML = progress[index].innerHTML.slice(0, progress[index].innerHTML.length - 1)
        }
      }, 200);
    },
    drawLine () {
      this.myChart = this.$echarts.init(document.getElementById('leida'), null, { renderer: 'svg' })
      //  
      this.myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'yxis'
        },
        // legend: {
        //   itemWidth: 500,
        //   itemHeight: 12,
        //   left: '15%',
        //   top: 0
        // },
        tooltip: {
          trigger: 'item'
        },
        radar: {
          indicator: this.indicator,
          triggerEvent: true, // 开启雷达图的边角名称可点击
          radius: '70%',
          shape: 'circle',
          name: {
            //formatter: '{value}',
            fontSize: '22rem'
          },
          nameGap: 15, //指示器名称和指示器轴的距离。
          // 雷达线类型
          splitLine: {
            lineStyle: {
              type: 'dashed',
            }
          },
          // 背景颜色，雷达区域
          splitArea: {
            areaStyle: {
              color: ['#FFFFFF',],
            }
          },
        },
        triggerEvent: true,
        //雷达边框颜色
        color: ['rgba(166,166,166,0.7)', '#37A0F4',],
        series: [{
          type: 'radar',
          data: [{
            value: this.last_week,
            areaStyle: {
              normal: {
                color: this.last, // 选择区域颜色
              }
            },
            name: "上周做题量"
          },
          {
            value: this.this_week,
            areaStyle: {
              normal: {
                color: this.week, // 选择区域颜色
              }
            },
            name: "本周做题量"
          }
          ]
        }],
      })

      //雷达的点击事件
      this.myChart.on('click', (params) => {
        if (params.componentType === 'radar') {
          // 点击到了 radar坐标轴标签 上
          console.log(params);
          var asd = this.info.thisWeekQuestionNum.findIndex(item => {
            return item.subjectName == params.name
          })
          if (asd != -1) {
            this.upNumber = this.info.thisWeekQuestionNum[asd].num
            for (let index = 0; index < this.indicator.length; index++) {
              this.indicator[index].color = ''
            }
            this.indicator[asd].color = '#37A0F4'
            this.clear()
            this.drawLine()
          } else {
            this.upNumber = ''
          }
        } else if (params.componentType === 'series') {

        }
      })

      window.addEventListener('resize', this.resizeChart)

    },
    resizeChart () {
      // let myChart = this.$echarts.init(document.getElementById('leida'))
      this.myChart.resize()
    },
    clear () {
      if (this.myChart) {
        this.myChart.dispose()
      }
      // this.drawLine()
    },
    toLeida () {
      this.$router.push('/study_analysis')
    },
    getProgressBakcground (item) {
      if (Number(item.contrast) > 0) {
        return '#37A0F4'
      } else {
        return '#F67878'
      }
    },
    getMath (item) {
      // if (item.contrast < 0) {
      //   return 0
      // }
      return Math.abs(item.contrast)
    },
    checkThisWeek () {
      this.week = '#37A0F4'
      this.last = '#E5E5E5'
      this.clear()
      this.drawLine()
    },
    checkLastWeek () {
      this.week = '#E5E5E5'
      this.last = '#37A0F4'
      this.clear()
      this.drawLine()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeChart)
  }
}
</script>

<style lang="scss" scoped>
.refrence_warp {
  height: 100vh;
  // overflow: scroll;
  background: #f6f6f6;
  .top {
    display: flex;
    height: 400rem;
    margin: 45rem 40rem 40rem 40rem;

    font-size: 24rem;
    .left {
      flex-shrink: 0;
      width: 600rem;
      background-image: url(../../assets/bg-学情分析@2x.png);
      background-size: 100% 100%;
      height: 100%;
      margin-right: 21rem;
      background-repeat: no-repeat;
      .head {
        margin: 30rem;
        height: 174rem;
        display: flex;
        .avatar {
          width: 70rem;
          height: 70rem;
          margin-right: 11rem;
          img {
            width: 70rem;
            height: 70rem;
          }
        }
        .text {
          flex: 1;
          color: white;
          font-size: 24rem;
          font-weight: bold;
          margin-left: 20rem;
          span {
            line-height: 53rem;
            &:nth-child(1) {
              flex: 1;
              display: inline-block;
              vertical-align: middle;
              max-width: 278rem;
              overflow: hidden;
              word-break: break-word;
              max-height: 172rem;

              span {
                width: 100%;
                height: 100%;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            &:nth-child(2) {
              width: 40rem;
              text-align: center;
            }
            &:nth-child(3) {
              flex: 1;
            }
          }
        }
      }
      .shuju {
        // margin-top: 89rem;
        margin-left: 30rem;

        font-size: 24rem;
        font-weight: bold;
        color: #ffffff;
      }
      .number {
        display: flex;
        margin-top: 32rem;
        .item {
          flex: 1;
          .m {
            font-size: 36rem;
            font-weight: bold;
            color: #ffffff;
            line-height: 27rem;
            // text-indent: 50rem;
            text-align: center;
          }
          .n {
            text-align: center;
            font-weight: 500;
            color: #e1effa;
            margin-top: 23rem;
            font-size: 24rem;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      border-radius: 11rem;
      border: 1rem solid #ececec;
      background: #ffffff;
    }
  }

  .autoModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1.5;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    .btntext {
      position: fixed;
      left: 800rem;
      top: 700rem;
      .text {
        color: var(--light-333333, #333);
        text-align: center;

        font-size: 24rem;
        font-style: normal;
        font-weight: 600;
        line-height: 36rem; /* 150% */
      }
    }
    .openButton {
      ::v-deep .el-button {
        display: flex;
        width: 360rem;
        height: 64rem;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        flex-shrink: 0;
        border-radius: 8rem;
        background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
        box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
      }
    }
  }
  .bottom {
    display: flex;
    border-radius: 11rem;
    background: #ffffff;
    margin: 0 40rem;
    padding-bottom: 10rem;
    padding-top: 10rem;
    .title {
      position: relative;
      font-size: 24rem;
      font-weight: bold;
      color: #000000;
      margin: 10rem 0;
      text-indent: 16rem;
      .more {
        position: absolute;
        right: 20rem;
        text-align: right;
        display: inline-block;
        font-size: 24rem;
        font-weight: bold;
        cursor: pointer;
        color: #999999;
        i {
          margin-left: -17rem;
        }
      }
    }
    .left_leida {
      position: relative;
      #leida {
        margin-left: 30rem;
      }
      .liang {
        display: flex;
        justify-content: center;
        font-size: 22rem;
        .up,
        .down {
          cursor: pointer;
          span {
            display: inline-block;
            width: 13rem;
            height: 13rem;
            border-radius: 50%;
          }
        }
        .down {
          margin-left: 25rem;
        }
      }
    }
    .right_defenlv {
      flex: 1;
      .point_warp {
        margin-top: 17rem;
        .point_item {
          margin-top: 21rem;
          display: flex;
          .name {
            text-indent: 16rem;
            width: 240rem;
            font-size: 24rem;
            font-weight: 500;
            color: #666666;
          }
          .progress {
            flex: 1;
          }
          .txt {
            margin-left: 50rem;
            font-size: 28rem;
            font-weight: bold;
            width: 100rem;
          }
          .status {
            width: 200rem;
            display: flex;
            margin-left: 50rem;
            font-weight: bold;
            align-items: center;
            span {
              font-size: 28rem;
              padding: 0 5rem;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
.yuan {
  pointer-events: none;
  width: 205rem;
  height: 205rem;
  border: 3rem solid #cfe6ff;
  border-radius: 50%;
  position: absolute;
  top: 79rem;
  left: 75rem;
}

::v-deep .el-progress-bar__outer {
  height: 10rem !important;
}
::v-deep .el-progress-bar__outer {
  background: #e5e5e5 !important;
}
// .xia {
//   color: rgba(255, 97, 71, 1);
// }
// .ping {
//   background: #37a0f4;
// }
// .shang {
//   background: #37a0f4;
// }
.progress {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}
.my_progress {
  background: #e5e5e5;
  border-radius: 10rem;
  height: 18rem;
  width: 85%;
}
.desc {
  text-align: center;
  text-indent: 15rem;
  color: #37a0f4;
  height: 20rem;
  font-size: 22rem;
}
</style>