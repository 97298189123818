<template>
  <div style="width:100%">
    <div class="head">
      <div class="title"
           @click="toQuxian">
        {{userGrade==1?'考点曲线':'考点曲线'}}
      </div>
      <div class="subject">
        <el-dropdown style="margin-left:20rem;margin-top:-5rem;color:#2196F3">
          <span class="el-dropdown-link">
            {{subject_name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in list"
                              @click.native.prevent="checkItem(item)"
                              :key="item.subject_id + ''">{{item.subject_name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <span class="more"
            @click="toQuxian">更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <!-- <div id="myCishu"
         style="width:100%;height:300rem"></div> -->
    <chart ref='chart'
           myId="charts"
           :xValues="xValues"
           :color="color"
           :series="series"></chart>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { mapMutations, mapState } from 'vuex'
import chart from '@/views/components/charts/lineChart.vue'
import { getKnowledgeLine, getPointLine } from '@/api/analysis.js'

export default {
  data () {
    return {
      xValues: [],
      color: ['#E86951', '#E7BC54', '#4493E5', '#6DCE92', '#5C50EE'],
      series: [],
      subject_name: '',
      userGrade: 0
    }
  },
  components: { chart },
  mounted () {
    // this.userGrade = JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id
    this.getSubjectList()
  },
  computed: {
    subjects () {
      return this.$store.state.subjectList
    },
    // ...mapState(['subjectList']),
    list () {
      return this.subjects.filter(item => item.subject_id != 13)
    }
  },
  methods: {

    async getData (id) {
      this.series = []
      this.xValues = []
      // this.$http({
      //   url: '/api/v1/report/know_charts',
      //   method: 'get',
      //   params: {
      //     subject_id: id
      //   }
      // }).then(res => {
      //   res.data.list.forEach(item => {
      //     if (item.rate.length == 0) {
      //       item.rate = [0, 0, 0, 0, 0]
      //     }
      //     let obj = {
      //       name: item.knowledge_desc,
      //       type: 'line',
      //       data: item.rate
      //     }
      //     this.series.push(obj)
      //     this.xValues.push('')
      //   });
      // this.drawLine1()
      // this.$ref.drawLine1()
      //})
      let maxLength = 5
      if (this.userGrade != 1) {
        const { data } = await getKnowledgeLine({
          subjectId: id
        })
        data.forEach(item => {
          if (item.res.length == 0) {
            item.res = [0, 0, 0, 0, 0]
          }
          let obj = {
            name: item.knowledgeDesc,
            smooth: true,
            type: 'line',
            data: item.res
          }
          if (item.res.length > maxLength) {
            maxLength = item.res.length
          }
          this.series.push(obj)
          // this.xValues.push('')
        });
        for (let i = 0; i < maxLength; i++) {
          this.xValues.push('')
        }
      } else {
        const { data } = await getPointLine({
          subjectId: id
        })
        data.forEach(item => {
          if (item.res.length == 0) {
            item.res = [0, 0, 0, 0, 0]
          }
          let obj = {
            name: item.checkPointDesc,
            smooth: true,
            type: 'line',
            data: item.res
          }
          if (item.res.length > maxLength) {
            maxLength = item.res.length
          }
          this.series.push(obj)
          this.xValues.push('')
        });
        for (let i = 0; i < maxLength; i++) {
          this.xValues.push('')
        }
      }


    },
    ...mapMutations(['SET_SUBJECT']),
    async getSubjectList () {
      if (!this.subjects.length) {
        const { data } = await getStudentSubject()
        this.SET_SUBJECT(data.list);
      }
      this.checkItem(this.subjects[0])

      // this.$http({
      //   url: '/api/v1/cp/subject_list',
      //   method: 'get'
      // }).then(res => {
      //   this.subjects = res.data.list
      //   this.checkItem(this.subjects[0])
      // })
    },
    checkSubject () {
      var arr = []
      this.xValues = []
      var item = this.quxianObj.subject_data[this.index + 1]
      for (const key in item.points) {
        let obj = {
          name: item.points[key],
          type: 'line',
          data: Object.values(item.point_checks[key])
        }
        this.xValues.push('')
        arr.push(obj)
      }
      this.series = arr

      // this.drawLine1()
    },
    // drawLine1 () {
    //   let myChart = this.$echarts.init(document.getElementById('myCishu'))
    //   myChart.setOption({
    //     tooltip: {
    //       trigger: 'axis',
    //     },
    //     color: this.color,
    //     grid: {
    //       top: '5%',
    //       left: '3%',
    //       right: '4%',
    //       bottom: '10%',
    //       containLabel: true
    //     },
    //     toolbox: {
    //       show: false,
    //       feature: {
    //         saveAsImage: {},
    //       }
    //     },
    //     xAxis: {
    //       type: 'category',
    //       boundaryGap: false,
    //       data: this.xValues
    //     },
    //     yAxis: {
    //       type: 'value',
    //       max: 100
    //     },
    //     series: this.series
    //   })
    // },
    checkItem (item) {
      this.subject_name = item.subject_name
      this.getData(item.subject_id)
    },
    toQuxian () {
      this.$router.push('/knowledgepoint')
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  display: flex;
  position: relative;
  font-size: 24rem;
  font-weight: bold;
  color: #000000;
  margin: 30rem;
}
.more {
  font-size: 22rem;
  display: flex;
  align-items: center;
  text-align: right;
  position: absolute;
  right: 40rem;
  color: #999999;
  cursor: pointer;
}

::v-deep .el-dropdown {
  font-size: 20rem !important;
  font-weight: bold !important;
  color: #2196f3 !important;
}
</style>